import palette from '../palette';
const MuiBreadcrumbs = {
    styleOverrides: {
        root: {
            textTransform: 'capitalize',
            lineHeight: '18px !important'
        },
    }
};

export default MuiBreadcrumbs;
