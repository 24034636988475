export const ONDC_COLORS = {
  ACCENTCOLOR: "#1c75bc",
  BACKGROUNDCOLOR: "#f0f0f0",
  PRIMARYCOLOR: "#606161",
  SECONDARYCOLOR: "#606161",
  ERROR: "#FF5959",
  SUCCESS: "#2EB086",
  WHITE: "#ffffff",
  WARNING: "#F9C132",
};
