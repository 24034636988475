const stdCodes = [
    {
        code: "080",
        city: ["Bengaluru", "Bengluru", "Bangalore"]
    },
    {
        code: "011",
        city: ["New Delhi", "Delhi", "Dilli"]
    },
    {
        code: "0422",
        city: ["Coimbatore", "Coimbator"]
    },
    {
        code: "0364",
        city: ["Shillong"]
    },
    {
        code: "0522",
        city: ["Lucknow", "Lakhnao"]
    },
    {
        code: "0755",
        city: ["Bhopal", "Bhopaal"]
    },
    {
        code: "044",
        city: ["Chennai", "Madras"]
    },
    {
        code: "022",
        city: ["Mumbai", "Bombay"]
    },
    {
        code: "020",
        city: ["Pune", "Poona"]
    },
    {
        code: "040",
        city: ["Hyderabad", "Hydrabad"]
    },
    {
        code: "0145",
        city: ["Ajmer", "Ajmeer"]
    }
];

export default stdCodes;
