import { postCall } from "./axios";

export const searchByCityCall = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await postCall(`/clientApis/v2/search_by_city`, data);

            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const searchByItemCall = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await postCall(`/clientApis/v2/search_by_item`, data);

            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
};


export const searchByFulfillmentEndLocationCall = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await postCall(`/clientApis/v2/search_by_fulfillment_end_location`, data);

            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const getSellerInfo = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await postCall(`/clientApis/v2/info`, data);

            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const searchByCategoryCall = (data) => {

    return new Promise(async (resolve, reject) => {
        try {
            const response = await postCall(`/clientApis/v2/search_by_category`, data);

            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
};
